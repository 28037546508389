import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
  <>
    <Helmet>
      <title>{"Preferencemaker"}</title>
    </Helmet>
    <div className="bg-background-300 text-text-950"
    >
      <div className="container mx-auto flex flex-col items-center justify-center h-screen px-4 text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Welcome to <span className='text-primary-primary'>PreferenceMaker</span>
        </h1>
        <p className="text-xl md:text-2xl mb-8 text-text-950">
          Create Bracket to find out what you prefer! 
        </p>
        <div className='flex flex-row space-x-7'>
        <a href="#categories" className="bg-secondary-secondary hover:bg-secondary-600 text-xl text-text-50 font-bold py-5 px-8 rounded">
          Browse
        </a>
        <Link to='/submit-preference' className="bg-primary-primary hover:bg-primary-300 text-xl text-text-text font-bold py-5 px-8 rounded">
          Create
        </Link>
        </div>
      </div>
    </div>
  </>
  );
};

export default HeroSection;
