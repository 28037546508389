// src/components/CategoryCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CategoryCard = ({ id, title, coverPhotoURL }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/preference/${id}`);
  };

  return (
    <div
      className="bg-background-200 shadow-lg rounded-lg p-4 w-60 h-80 mb-6 flex flex-col justify-between cursor-pointer"
      onClick={handleClick}
    >
      <div className="h-48 bg-cover bg-center rounded-t-lg" style={{ backgroundImage: `url(${coverPhotoURL})` }}></div>
      <h3 className="text-xl font-bold mt-4 text-text-900 text-center">{title}</h3>
    </div>
  );
};

export default CategoryCard;