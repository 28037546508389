import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ref, get, runTransaction } from 'firebase/database';
import { database } from '../firebaseConfig'; // Ensure this path is correct

const PlayPage = () => {
  const { state } = useLocation();
  const { photos, gameId } = state || { photos: [], gameId: '' };
  const [currentPhotos, setCurrentPhotos] = useState([]);
  const [remainingPhotos, setRemainingPhotos] = useState(photos);
  const [winners, setWinners] = useState([]);
  const [round, setRound] = useState(1);
  const [totalRounds, setTotalRounds] = useState(Math.ceil(Math.log2(photos.length)));
  const [updatedStats, setUpdatedStats] = useState(new Map());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const photosRef = ref(database, `preferences/${gameId}/photos`);
        const snapshot = await get(photosRef);
        if (snapshot.exists()) {
          const photosData = snapshot.val();
          const updatedPhotos = photos.map(photo => ({
            ...photo,
            ...photosData[photo.id],
          }));
          setRemainingPhotos(updatedPhotos);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [gameId, photos]);

  useEffect(() => {
    if (remainingPhotos.length <= 1 && winners.length > 0 && round < totalRounds) {
      setRemainingPhotos(winners);
      setWinners([]);
      setRound((prevRound) => prevRound + 1);
    } else if (remainingPhotos.length > 1) {
      const pairs = createPairs(remainingPhotos);
      setCurrentPhotos(pairs[0]);
    } else if (remainingPhotos.length === 1) {
      setWinners((prevWinners) => [...prevWinners, remainingPhotos[0]]);
      setRemainingPhotos([]);
    }
  }, [remainingPhotos, winners, round, totalRounds]);

  useEffect(() => {
    if (currentPhotos.length === 0 && remainingPhotos.length > 1) {
      const pairs = createPairs(remainingPhotos);
      setCurrentPhotos(pairs[0]);
    }
  }, [currentPhotos, remainingPhotos]);

  const createPairs = (photoList) => {
    const shuffled = [...photoList].sort(() => 0.5 - Math.random());
    const pairs = [];
    for (let i = 0; i < shuffled.length; i += 2) {
      if (shuffled[i + 1]) {
        pairs.push([shuffled[i], shuffled[i + 1]]);
      } else {
        pairs.push([shuffled[i]]);
      }
    }
    return pairs;
  };

  const updatePhotoStats = async (chosenPhoto, otherPhoto) => {
    try {
      // Transaction for the chosen photo
      const chosenPhotoRef = ref(database, `preferences/${gameId}/photos/${chosenPhoto.id}`);
      await runTransaction(chosenPhotoRef, (currentData) => {
        if (currentData) {
          return {
            ...currentData,
            wins: (currentData.wins || 0) + 1,
            total: (currentData.total || 0) + 1,
          };
        } else {
          return chosenPhoto; // If no data exists, initialize it
        }
      });
  
      // Transaction for the other photo (if applicable)
      if (otherPhoto) {
        const otherPhotoRef = ref(database, `preferences/${gameId}/photos/${otherPhoto.id}`);
        await runTransaction(otherPhotoRef, (currentData) => {
          if (currentData) {
            return {
              ...currentData,
              losses: (currentData.losses || 0) + 1,
              total: (currentData.total || 0) + 1,
            };
          } else {
            return otherPhoto; // If no data exists, initialize it
          }
        });
      }
  
      // Updating the state
      updatedStats.set(chosenPhoto.id, { ...chosenPhoto, wins: (chosenPhoto.wins || 0) + 1 });
      if (otherPhoto) {
        updatedStats.set(otherPhoto.id, { ...otherPhoto, losses: (otherPhoto.losses || 0) + 1 });
      }
      setUpdatedStats(new Map(updatedStats));
  
    } catch (error) {
      console.error('Failed to update photo stats:', error);
    }
  };

  const updateFinalRoundData = async (finalWinner) => {
    try {
      // Transaction for updating the final winner's stats
      const finalWinnerRef = ref(database, `preferences/${gameId}/photos/${finalWinner.id}`);
      await runTransaction(finalWinnerRef, (currentData) => {
        if (currentData) {
          return {
            ...currentData,
            firstPlaces: (currentData.firstPlaces || 0) + 1,
          };
        } else {
          return {
            ...finalWinner,
            firstPlaces: 1, // Initialize firstPlaces if no data exists
          };
        }
      });
  
      // Transaction for updating total games played in this game
      const gameRef = ref(database, `preferences/${gameId}`);
      await runTransaction(gameRef, (gameData) => {
        if (gameData) {
          return {
            ...gameData,
            totalGames: (gameData.totalGames || 0) + 1,
          };
        } else {
          return {
            totalGames: 1, // Initialize totalGames if no data exists
          };
        }
      });
  
      // Update local state
      const updatedFinalWinner = {
        ...finalWinner,
        firstPlaces: (updatedStats.get(finalWinner.id)?.firstPlaces || 0) + 1,
      };
      updatedStats.set(finalWinner.id, updatedFinalWinner);
      setUpdatedStats(new Map(updatedStats));
  
    } catch (error) {
      console.error('Failed to update final round data:', error);
    }
  };

  const handleChoice = async (chosenPhoto) => {
    const otherPhoto = currentPhotos.find((photo) => photo.id !== chosenPhoto.id);

    await updatePhotoStats(chosenPhoto, otherPhoto);

    const newWinners = [...winners, chosenPhoto];
    setWinners(newWinners);

    const newRemainingPhotos = remainingPhotos.filter(photo => photo.id !== chosenPhoto.id && photo.id !== otherPhoto?.id);
    setRemainingPhotos(newRemainingPhotos);

    if (newRemainingPhotos.length === 1) {
      const finalPhoto = newRemainingPhotos[0];
      setWinners((prevWinners) => [...prevWinners, finalPhoto]);
      setRemainingPhotos([]);
    }

    if (round === totalRounds) {
      // Ensure the stats are updated before navigating
      await updateFinalRoundData(chosenPhoto);
      navigate('/winner', { state: { winnerId: chosenPhoto.id, id: gameId } });
    }
  };

  return (
    <div className='bg-background-700'>
      <div className="container mx-auto py-10">
        <h1 className="text-4xl font-bold mb-4 text-center">Round {round}</h1>
        <div className="flex justify-center items-center">
          {currentPhotos.length === 0 ? (
            <div>No photos available</div>
          ) : (
            <div className="flex justify-center items-center">
              <div className="w-1/2 px-4 text-center">
                <img
                  src={currentPhotos[0].url}
                  alt="Current Choice"
                  className="w-full h-[500px] object-cover rounded-lg mb-4 cursor-pointer transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                  onClick={() => handleChoice(currentPhotos[0])}
                />
              </div>
              <div className="text-6xl font-bold mx-4 text-text-text">VS</div>
              {currentPhotos[1] && (
                <div className="w-1/2 px-4 text-center">
                  <img
                    src={currentPhotos[1].url}
                    alt="Current Choice"
                    className="w-full h-[500px] object-cover rounded-lg mb-4 cursor-pointer transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                    onClick={() => handleChoice(currentPhotos[1])}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayPage;
