import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';
import CategorySection from './components/CategorySection';
import SubmitPreferencePage from './pages/SubmitPreferencePage';
import PreferenceDetail from './pages/PreferenceDetail';
import PlayPage from './pages/PlayPage';
import WinnerPage from './pages/WinnerPage';
import LoginPage from './pages/LoginPage'; // Import the login page
import CategoryPage from './pages/CategoryPage';
import CategoryDetailPage from './pages/CategoryDetailPage';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { HelmetProvider } from 'react-helmet-async';
function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    // Log a page view event every time the route changes
    logEvent(analytics, 'page_view', { page_path: location.pathname });
  }, [location]);

  return null;
}

function App() {
  return (
  <HelmetProvider>
    <Router>
      <AnalyticsTracker />
      <div className="app-container">
        <Navbar />
        <div className="app-content">
          <Routes>
            <Route path="/" element={
              <>
                <HeroSection />
                <CategorySection />
              </>
            } />
            <Route path="/submit-preference" element={<SubmitPreferencePage />} />
            <Route path="/play/:id" element={<PlayPage />} />
            <Route path="/preference/:id" element={<PreferenceDetail />} />
            <Route path="/winner" element={<WinnerPage />} />
            <Route path="/login" element={<LoginPage />} /> {/* Add the login route */}
            <Route path="/categories" element={<CategoryPage />} />
            <Route path="/category/:categoryName" element={<CategoryDetailPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
    </HelmetProvider>
  );
}

export default App;