import React from 'react';
import CategoryCard from './CategoryCard';

const CategoryRow = ({ title, preferences }) => {
  return (
    <div className="mb-10">
      <h2 className="text-2xl font-bold mb-4 text-text-text">{title}</h2>
      <div className="flex flex-wrap justify-center space-x-4">
        {preferences.map((preference) => (
          <CategoryCard key={preference.id} id={preference.id} title={preference.title} coverPhotoURL={preference.coverPhotoURL} />
        ))}
      </div>
    </div>
  );
};

export default CategoryRow;