import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import { Helmet } from 'react-helmet-async';

const PreferenceDetail = () => {
  const { id } = useParams();
  const [preference, setPreference] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [sortCriteria, setSortCriteria] = useState('firstPlaces'); // Default sorting criteria
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPreference = async () => {
      const preferenceRef = ref(database, `preferences/${id}`);
      const snapshot = await get(preferenceRef);
      if (snapshot.exists()) {
        const preferenceData = snapshot.val();
        setPreference(preferenceData);

        const photosArray = Object.keys(preferenceData.photos).map((key) => ({
          id: key,
          ...preferenceData.photos[key],
        }));
        setPhotos(sortPhotos(photosArray, sortCriteria));
      } else {
        console.error("Preference not found");
      }
    };

    fetchPreference();
  }, [id, sortCriteria]); // Re-fetch and sort photos when sort criteria changes

  const sortPhotos = (photosArray, criteria) => {
    return photosArray.sort((a, b) => {
      switch (criteria) {
        case 'winRate':
          return ((b.wins || 0) / (b.wins + b.losses || 1)) - ((a.wins || 0) / (a.wins + a.losses || 1));
        case 'wins':
          return (b.wins || 0) - (a.wins || 0);
        case 'losses':
          return (b.losses || 0) - (a.losses || 0);
        default:
          return (b.firstPlaces || 0) - (a.firstPlaces || 0);
      }
    });
  };

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value);
  };

  const handlePlay = () => {
    if (photos.length) {
      navigate(`/play/${id}`, { state: { photos, gameId: id } }); // Pass sorted photos and gameId
    } else {
      console.error('No photos available to start the game');
    }
  };

  if (!preference) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{preference.title}</title>
        <meta name="description" content={preference.description} />
        <meta property="og:title" content={preference.title} />
        <meta property="og:description" content={preference.description} />
        <meta property="og:image" content={preference.coverPhotoURL} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={preference.title} />
        <meta name="twitter:description" content={preference.description} />
        <meta name="twitter:image" content={preference.coverPhotoURL} />
      </Helmet>

      <div className='bg-background-700'>
        <div className="container mx-auto py-10 flex ">
          <div className="flex-1 overflow-hidden">
            <h1 className="text-4xl font-bold mb-4 break-words">{preference.title}</h1>
            <img src={preference.coverPhotoURL} alt={preference.title} className="w-4/6 h-auto rounded-lg mb-4" />
            <p className="text-xl mb-4 break-words">{preference.description}</p>
            <button
              onClick={handlePlay}
              className="bg-accent-accent hover:bg-accent-700 ml-2 text-text-950 text-2xl font-bold py-6 px-12 rounded-lg focus:outline-none focus:shadow-outline mb-4 outline outline-2 outline-black"
            >
              Play
            </button>
          </div>
          <div className="w-1/3 ml-5 overflow-hidden">
            <h2 className="text-2xl font-bold mb-4">Stats</h2>
            <div className="mb-4">
              <label htmlFor="sortCriteria" className="text-text-50 text-sm font-bold mb-2">Sort By:</label>
              <select
                id="sortCriteria"
                value={sortCriteria}
                onChange={handleSortChange}
                className="ml-2 py-2 px-4 rounded shadow bg-secondary-500 text-text-950"
              >
                <option value="firstPlaces">First Places</option>
                <option value="winRate">Win Rate</option>
                <option value="wins">Wins</option>
                <option value="losses">Losses</option>
              </select>
            </div>
            <div className="overflow-y-auto max-h-screen pr-2">
              <div className="grid grid-cols-1 gap-4">
                {photos.length > 0 ? (
                  photos.map((photo, index) => (
                    <div key={index} className="flex items-center">
                      <img src={photo.url} alt={`Preference ${index + 1}`} className="w-20 h-auto rounded-lg mr-4" />
                      <div className="text-lg">
                        <p>Wins: {photo.wins}</p>
                        <p>Losses: {photo.losses}</p>
                        <p>First Places: {photo.firstPlaces}</p>
                        <p>Win Rate: {((photo.wins / (photo.wins + photo.losses)) * 100).toFixed(2)}%</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-text-50">No photos available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferenceDetail;
