import React from 'react';
import { Helmet } from 'react-helmet';
import PreferenceForm from '../components/PreferenceForm';

const SubmitPreferencePage = () => {
  return (
    <div>
      <Helmet>
        <title>{"Submit"}</title>
      </Helmet>
      <PreferenceForm />
    </div>
  );
};

export default SubmitPreferencePage;
