import React from 'react';
import { useNavigate } from 'react-router-dom';

const CategoryGridCard = ({ categoryName, coverPhotoURL }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/category/${categoryName}`);
  };

  return (
    <div
      className="bg-background-300 shadow-md rounded-lg p-6 flex flex-col justify-between cursor-pointer hover:shadow-xl transition-shadow duration-300"
      onClick={handleClick}
    >
      <div className="h-40 bg-cover bg-center rounded-lg mb-4" style={{ backgroundImage: `url(${coverPhotoURL})` }}></div>
      <h3 className="text-2xl font-semibold text-text-950 text-center">{categoryName}</h3>
    </div>
  );
};

export default CategoryGridCard;
