import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-navbar p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}

        <Link to='' className="text-white text-2xl font-bold">PREFERENCEMAKER</Link>

        {/* Links for larger screens */}
        <div className="hidden md:flex space-x-4 text-lg">
        <Link
            to="/categories"
            className="text-white hover:text-gray-300 px-6 py-3"
          >
            Categories
          </Link>
        <Link
            to="/login"
            className="text-white hover:text-gray-300 px-6 py-3"
          >
            Login
          </Link>
          <Link
            to="/submit-preference"
            className="inline-block px-6 py-3 bg-primary-500 text-text-50 font-bold rounded transition-transform transform hover:scale-105 hover:bg-primary-600"
          >
            Create one!
          </Link>
        </div>
        {/* Mobile menu toggle button */}
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
           <Link
            to="/categories"
            className="block text-white py-2 px-4 hover:bg-blue-700"
          >
            Categories
          </Link>
        <Link
            to="/login"
            className="block text-white py-2 px-4 hover:bg-blue-700"
          >
            Login
          </Link>
          <Link
            to="/submit-preference"
            className="block text-white py-2 px-4 hover:bg-blue-700"
          >
            Create one!
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
