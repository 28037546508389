import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { database, storage } from '../firebaseConfig';
import { ref as dbRef, push, get, update } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../authContext';

const categories = [
  'VideoGames',
  'Anime',
  'Sports',
  'Music',
  'Movies',
  'TV Shows',
  'Books',
  'Technology',
  'Food',
  'Fashion',
  'Art',
  'Nature',
  'History',
  'Science',
  'Health & Fitness',
  'Education',
  'Cars & Vehicles',
  'Pets & Animals',
  'Memes',
  'Geography',
  'Other',
  'Board Games',
  'Celebrities',
  'Actors',
  'Drinks',
];

const MAX_IMAGES = 200;
const MIN_IMAGES = 3;
const MAX_TOTAL_SIZE_MB = 100;
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;
const MAX_GAMES_PER_DAY = 5; // Max games a user can create per day

const IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

const PreferenceForm = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState(categories[0]);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [loading, setLoading] = useState(false);

  const isValidImage = (file) => IMAGE_MIME_TYPES.includes(file.type);

  const handleCoverPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file && isValidImage(file)) {
      setCoverPhoto(file);
    } else {
      alert('Please upload a valid image file (JPEG, PNG, WEBP).');
    }
  };

  const handleRemoveCoverPhoto = () => {
    setCoverPhoto(null);
  };

  const handlePhotoChange = (e) => {
    const selectedPhotos = Array.from(e.target.files).filter(isValidImage);
    const newTotalSize = selectedPhotos.reduce((acc, photo) => acc + photo.size, totalSize);

    if (photos.length + selectedPhotos.length > MAX_IMAGES) {
      alert(`You can only upload a maximum of ${MAX_IMAGES} images.`);
      return;
    }

    if (newTotalSize > MAX_TOTAL_SIZE_BYTES) {
      alert(`Total file size should not exceed ${MAX_TOTAL_SIZE_MB} MB.`);
      return;
    }

    if (selectedPhotos.length === 0) {
      alert('Please upload valid image files only (JPEG, PNG, WEBP).');
      return;
    }

    setPhotos([...photos, ...selectedPhotos]);
    setTotalSize(newTotalSize);
  };

  const handleRemovePhoto = (index) => {
    const newPhotos = photos.filter((_, i) => i !== index);
    const newTotalSize = newPhotos.reduce((acc, photo) => acc + photo.size, 0);

    setPhotos(newPhotos);
    setTotalSize(newTotalSize);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      alert('You need to be signed in to create a preference game.');
      return;
    }

    if (photos.length < MIN_IMAGES) {
      alert(`You must upload at least ${MIN_IMAGES} images.`);
      return;
    }

    setLoading(true);

    try {
      const userId = currentUser.uid;
      const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
      const userRef = dbRef(database, `users/${userId}/dailyGameCount/${today}`);

      // Check the user's game creation count for today
      const snapshot = await get(userRef);
      let gameCount = snapshot.exists() ? snapshot.val().count : 0;

      if (gameCount >= MAX_GAMES_PER_DAY) {
        alert(`You've reached the daily limit of ${MAX_GAMES_PER_DAY} games.`);
        setLoading(false);
        return;
      }

      // Upload cover photo and photos
      const preferenceId = uuidv4();
      let coverPhotoURL = '';

      if (coverPhoto) {
        const coverPhotoStorageRef = storageRef(storage, `${category}/${preferenceId}/coverPhoto`);
        await uploadBytes(coverPhotoStorageRef, coverPhoto);
        coverPhotoURL = await getDownloadURL(coverPhotoStorageRef);
      }

      const photoData = {};
      await Promise.all(
        photos.map(async (photo) => {
          const photoId = uuidv4();
          const photoStorageRef = storageRef(storage, `${category}/${preferenceId}/photos/${photoId}`);
          await uploadBytes(photoStorageRef, photo);
          const url = await getDownloadURL(photoStorageRef);
          photoData[photoId] = { id: photoId, url, wins: 0, losses: 0, firstPlaces: 0, total: 0 };
        })
      );

      // Create the new preference in the database
      const newPreferenceRef = await push(dbRef(database, 'preferences'), {
        userId,
        title,
        description,
        category,
        totalGames: 0,
        coverPhotoURL,
        photos: photoData,
      });

      // Update the user's game creation count
      await update(userRef, {
        count: gameCount + 1,
        lastUpdated: new Date().getTime(),
      });

      setTitle('');
      setDescription('');
      setCategory(categories[0]);
      setCoverPhoto(null);
      setPhotos([]);
      setTotalSize(0);

      navigate(`/preference/${newPreferenceRef.key}`);
    } catch (error) {
      console.error('Error submitting preference: ', error);
      alert('Failed to submit preference. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-background-700 min-h-screen flex items-center justify-center p-4">
      <form onSubmit={handleSubmit} className="bg-background-400 p-10 rounded-lg shadow-lg w-full max-w-2xl">
        <h2 className="text-3xl font-bold mb-6 text-text-950 text-center">Submit Your Preference</h2>
        <div className="mb-6">
          <label className="block text-text-50 text-sm font-bold mb-2" htmlFor="title">
            Title
          </label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            disabled={loading}
          />
        </div>
        
        <div className="mb-6">
          <label className="block text-text-50 text-sm font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            disabled={loading}
          />
        </div>
        
        <div className="mb-6">
          <label className="block text-text-50 text-sm font-bold mb-2" htmlFor="category">
            Category
          </label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
            disabled={loading}
          >
            {categories.map((cat, index) => (
              <option key={index} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        
        <div className="mb-6">
          <label className="block text-text-50 text-sm font-bold mb-2" htmlFor="coverPhoto">
            Cover Photo
          </label>
          <input
            id="coverPhoto"
            type="file"
            accept=".png, .jpg, .jpeg, .webp"
            onChange={handleCoverPhotoChange}
            className="shadow appearance-none border rounded w-full py-3 px-4 text-text-text leading-tight focus:outline-none focus:shadow-outline"
            disabled={loading}
          />
          {coverPhoto && (
            <div className="mt-4 relative">
              <img src={URL.createObjectURL(coverPhoto)} alt="Cover Preview" className="w-full h-auto rounded-lg" />
              <button
                type="button"
                onClick={handleRemoveCoverPhoto}
                className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded"
              >
                Remove
              </button>
            </div>
          )}
        </div>
        
        <div className="mb-6">
          <label className="block text-text-50 text-sm font-bold mb-2" htmlFor="photos">
            Photos of Preferences (Min {MIN_IMAGES} images, Max {MAX_IMAGES} images, total size {MAX_TOTAL_SIZE_MB} MB)
          </label>
          <input
            id="photos"
            type="file"
            accept=".png, .jpg, .jpeg, .webp"
            multiple
            onChange={handlePhotoChange}
            className="shadow appearance-none border rounded w-full py-3 px-4 text-text-text leading-tight focus:outline-none focus:shadow-outline"
            required
            disabled={loading}
          />
          {photos.length > 0 && (
            <div className="mt-4 grid grid-cols-2 gap-4">
              {photos.map((photo, index) => (
                <div key={index} className="relative">
                  <img src={URL.createObjectURL(photo)} alt={`Preference ${index + 1}`} className="w-full h-auto rounded-lg" />
                  <button
                    type="button"
                    onClick={() => handleRemovePhoto(index)}
                    className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div className="flex items-center justify-center">
          <button
            type="submit"
            className={`bg-secondary-500 hover:bg-secondary-secondary text-text-950 font-bold text-xl py-3 px-6 rounded focus:outline-none focus:shadow-outline ${!currentUser ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading || !currentUser}
          >
            {currentUser ? (loading ? 'Submitting...' : 'Submit') : 'You need to be logged in'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PreferenceForm;
