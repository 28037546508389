import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import '../index.css';

const WinnerPage = () => {
  const location = useLocation();
  const { winnerId, id: gameId } = location.state || { winnerId: '', id: '' };
  const [winner, setWinner] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [sortCriteria, setSortCriteria] = useState('firstPlaces'); // Default sorting criteria

  useEffect(() => {
    const fetchWinner = async () => {
      try {
        const winnerRef = ref(database, `preferences/${gameId}/photos/${winnerId}`);
        const snapshot = await get(winnerRef);
        if (snapshot.exists()) {
          setWinner(snapshot.val());
        } else {
          console.log('No winner data available');
        }
      } catch (error) {
        console.error('Failed to fetch winner:', error);
      }
    };

    const fetchPhotos = async () => {
      try {
        const photosRef = ref(database, `preferences/${gameId}/photos`);
        const snapshot = await get(photosRef);
        if (snapshot.exists()) {
          const photosData = snapshot.val();
          const photosArray = Object.keys(photosData).map(key => ({
            id: key,
            ...photosData[key]
          }));
          setPhotos(sortPhotos(photosArray, sortCriteria));
        } else {
          console.log('No photos available');
        }
      } catch (error) {
        console.error('Failed to fetch photos:', error);
      }
    };

    if (gameId && winnerId) {
      fetchWinner();
      fetchPhotos();
    }
  }, [gameId, winnerId, sortCriteria]); // Re-fetch photos when sort criteria changes

  const sortPhotos = (photosArray, criteria) => {
    return photosArray.sort((a, b) => {
      switch (criteria) {
        case 'winRate':
          return ((b.wins || 0) / (b.wins + b.losses || 1)) - ((a.wins || 0) / (a.wins + a.losses || 1));
        case 'wins':
          return (b.wins || 0) - (a.wins || 0);
        case 'losses':
          return (b.losses || 0) - (a.losses || 0);
        default:
          return (b.firstPlaces || 0) - (a.firstPlaces || 0);
      }
    });
  };

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value);
  };

  if (!winner || !gameId) {
    return <div>No winner data available</div>;
  }

  return (
    <div className='bg-background-700 p-20'>
      <div className="container mx-auto py-10 flex rounded-md bg-primary-950 shadow-xl">
        <div className="w-3/5 pr-4 flex flex-col items-center">
          <h1 className="text-5xl font-bold text-text-text mb-5">Winner</h1>
          <div className="w-4/5 px-2">
            <img
              src={winner.url}
              alt="Winner"
              className="w-full h-auto rounded-lg mb-4"
            />
            <div className="text-center mt-2 text-xl text-text-text">
              <p>Wins: {winner.wins}</p>
              <p>Losses: {winner.losses}</p>
              <p>First Places: {winner.firstPlaces}</p>
              <p>Win Rate: {((winner.wins) / (winner.wins + winner.losses) * 100).toFixed(2)}%</p>
            </div>
          </div>
        </div>

        <div className="w-2/5 overflow-y-auto max-h-screen flex flex-col items-center rounded-md mr-6">
          <h2 className="text-2xl font-bold mb-4 text-text-text">Stats</h2>
          <div className="mb-4 w-full text-center">
            <label htmlFor="sortCriteria" className="text-text-50 text-sm font-bold mb-2">
              Sort By:
            </label>
            <select
              id="sortCriteria"
              value={sortCriteria}
              onChange={handleSortChange}
              className="ml-2 py-2 px-4 rounded shadow bg-secondary-500 text-text-950"
            >
              <option value="firstPlaces">First Places</option>
              <option value="winRate">Win Rate</option>
              <option value="wins">Wins</option>
              <option value="losses">Losses</option>
            </select>
          </div>
          <div className='w-full overflow-y-auto max-h-screen flex flex-col items-center hide-scrollbar'>
            {photos.map((photo) => (
              <div key={photo.id} className="mb-4">
                <img
                  src={photo.url}
                  alt={photo.id}
                  className="w-56 h-auto rounded-lg"
                />
                <div className="text-center mt-2 text-text-text">
                  <p>Wins: {photo.wins || 0}</p>
                  <p>Losses: {photo.losses || 0}</p>
                  <p>First Places: {photo.firstPlaces || 0}</p>
                  <p>Win Rate: {((photo.wins || 0) / ((photo.wins || 0) + (photo.losses || 0)) * 100).toFixed(2)}%</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerPage;
