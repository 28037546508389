import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../firebaseConfig';
import { signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet';
const LoginPage = () => {
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Set up an auth state listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // If user is signed in, set the user state and redirect
        setUser(user);
        navigate('/');
      } else {
        // If user is not signed in, clear the user state
        setUser(null);
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);

      // The signed-in user info.
      const user = result.user;
      console.log('User Info:', user);

      // Navigate to home or another page
      navigate('/');
    } catch (error) {
      // Handle Errors here.
      console.error('Error signing in with Google:', error);

      // Display a user-friendly error message
      setError('Error signing in with Google. Please try again.');
    }
  };

  return (
    <>
    <Helmet>
      <title>{"Login"}</title>
    </Helmet>
    <div className='bg-background-700 min-h-screen flex items-center justify-center'>
      <div className="w-full max-w-md">
        <form className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
          <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          {!user && (
            <button
              type="button"
              onClick={handleGoogleSignIn}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline w-full"
            >
              Sign in with Google
            </button>
          )}
        </form>
      </div>
    </div>
  </>
  );
};

export default LoginPage;
