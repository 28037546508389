import React, { useState, useEffect } from 'react';
import { database } from '../firebaseConfig';
import { ref, onValue } from 'firebase/database';
import CategoryRow from './CategoryRow';

const CategorySection = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const preferencesRef = ref(database, 'preferences');
    onValue(preferencesRef, (snapshot) => {
      const data = snapshot.val();
      const categorizedData = {};

      for (const key in data) {
        const preference = data[key];
        if (!categorizedData[preference.category]) {
          categorizedData[preference.category] = [];
        }
        categorizedData[preference.category].push({
          id: key,
          ...preference,
        });
      }

      // Limit to the top 5 most played preferences per category based on totalGames
      const limitedCategorizedData = Object.entries(categorizedData).map(([category, preferences]) => {
        const sortedPreferences = preferences.sort((a, b) => (b.totalGames || 0) - (a.totalGames || 0));
        return [category, sortedPreferences.slice(0, 5)];
      });

      setCategories(limitedCategorizedData);
    });
  }, []);

  return (
    <div className="py-10 bg-background-700" id="categories">
      <div className="container mx-auto px-4">
        {categories.map(([category, preferences]) => (
          <CategoryRow key={category} title={category} preferences={preferences} />
        ))}
      </div>
    </div>
  );
};

export default CategorySection;
