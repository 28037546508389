import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import CategoryCard from '../components/CategoryCard';

const CategoryDetailPage = () => {
  const { categoryName } = useParams();
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const preferencesRef = ref(database, 'preferences');
        const snapshot = await get(preferencesRef);
        if (snapshot.exists()) {
          const allPreferences = snapshot.val();
          const filteredPreferences = Object.keys(allPreferences)
            .map(key => ({
              id: key,
              ...allPreferences[key]
            }))
            .filter(preference => preference.category === categoryName);

          setPreferences(filteredPreferences);
        } else {
          console.log('No preferences available');
        }
      } catch (error) {
        console.error('Failed to fetch preferences:', error);
      }
    };

    fetchPreferences();
  }, [categoryName]);

  return (
    <div className="bg-background-700 min-h-screen py-10">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-text-50 text-center">
          {categoryName}
        </h1>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
          {preferences.length > 0 ? (
            preferences.map(preference => (
              <CategoryCard
                key={preference.id}
                id={preference.id}
                title={preference.title}
                coverPhotoURL={preference.coverPhotoURL}
              />
            ))
          ) : (
            <p className="text-center text-text-50 col-span-full">
              No preferences found in this category.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryDetailPage;
