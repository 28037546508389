import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-navbar p-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center text-white">
        {/* Logo and Contact Info */}
        <div className="mb-4 md:mb-0 flex flex-col items-center md:items-start">
          <div className="text-lg font-bold mb-2 text-white">PREFERENCEMAKER</div>
        </div>
        
        {/* Social Media Links */}
        <div className="flex space-x-4">
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
