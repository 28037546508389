import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';
import CategoryGridCard from '../components/CategoryGridCard';

const CategoryPage = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const categoriesRef = ref(database, 'categories');
    
    onValue(categoriesRef, (snapshot) => {
      const data = snapshot.val();
      const categoriesArray = Object.keys(data).map((key) => ({
        name: key,
        coverPhotoURL: data[key].coverPhotoURL,
      }));
      
      setCategories(categoriesArray);
    });
  }, []);

  return (
    <div className="bg-background-700 min-h-screen py-10">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-text-50 text-center">
          Categories
        </h1>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6">
          {categories.map((category, index) => (
            <CategoryGridCard
              key={index}
              categoryName={category.name}
              coverPhotoURL={category.coverPhotoURL}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
