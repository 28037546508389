import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, googleProvider, database } from './firebaseConfig';
import { signInWithPopup, signOut } from 'firebase/auth';
import { ref as dbRef, set, get } from 'firebase/database';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await createUserDocument(user); // Ensure user document exists
      }
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      await createUserDocument(user); // Create user document in DB
      setCurrentUser(user);
    } catch (error) {
      console.error('Error signing in with Google: ', error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const createUserDocument = async (user) => {
    const userRef = dbRef(database, `users/${user.uid}`);
    const snapshot = await get(userRef);

    if (!snapshot.exists()) {
      // If the user does not exist, create the user document
      await set(userRef, {
        email: user.email,
        dailyGameCount: {}, // Initialize daily game count or other default user properties
      });
    }
  };

  const value = {
    currentUser,
    signInWithGoogle,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
